import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment';
import OutbrainBids from './OutbrainBids';
import DatePicker from 'react-datepicker';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datepicker/dist/react-datepicker.css';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Divider, FormControlLabel, Grid, Stack, Switch, Tooltip, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';

import OutbrainCreativeGroupBuilder from './OutbrainCreativeGroupBuilder';
import { getOutbrainMetadata, getSuggestedBids, storeFiles } from '../Api';
import { getPlatformSites } from '../Sites';
import { GLOBAL, OB, PLATFORMS, TASKS } from '../Constants';
import WidgetSelector from '../WidgetSelector';
import { parseCampaignName } from '../NameParser';
import { toast } from 'react-toastify';
import CampaignsNote from '../CampaignsNote';
import {
  displayWarnings,
  getImageDimensions,
  truncateFileName,
  validateAndPrepareImage
} from '../utils/imageUploadUtils';
import Prefix from '../Prefix';
import { getMaxCampaignIndex } from '../utils/commonUtils';
import {
  getCreationDate,
  getFileHash,
  getMultiSelectValue,
  getNewCreatorValue,
  getOnlyValue,
  getSelectValue,
  makeSelectOptions,
  parseBulkData
} from '../Shared';
import CropWindow from '../CropWindow';

const defaultOptions = {
  [OB.KEY_BUDGET]: OB.DEFAULT_BUDGET,
  [OB.KEY_DEFAULT_BID]: '',
  [OB.KEY_CREATIVE_GROUPS]: [],
  [OB.KEY_BIDS]: [],
  [OB.KEY_SITE]: OB.DEFAULT_SITE,
  [OB.KEY_OS_CHOICES]: [OB.IOS, OB.ANDROID, OB.MACOS, OB.WINDOWS, OB.ALL],
  [OB.KEY_PREFIX]: '',
  [OB.KEY_COUNTRY]: ['us'],
  [OB.KEY_DEVICE]: [OB.DESKTOP, OB.MOBILE],
  [OB.KEY_OS_DUPLICATION_TYPE]: 'separate',
  [OB.KEY_DEVICE_DUPLICATION_TYPE]: 'separate',
  [OB.KEY_SEMI_OPTIMIZATION]: 'off',
  [OB.KEY_MAX_CPC_LIMIT]: '',
  [OB.KEY_BID_STRATEGY]: 'MAX_CONVERSION_FULLY_AUTOMATED',
  [OB.KEY_BID_STRATEGY_UI]: 'MAX_CONVERSION_FULLY_AUTOMATED'
};
class OutbrainCreate extends Component {
  constructor(props) {
    super(props);

    this.suggestedBidsOB = [];
    this.defaultState = this.getDefaultState(props);
    this.state = this.defaultState;
    this.isSwag = props.programOptions.isSwag;
  }
  getDefaultState(props) {
    return {
      options: {
        ...defaultOptions,
        ...props.options,
        prefixes: [],
        prefix: ''
      },
      accounts: props.accounts || [],
      conversions: [],
      tooltips: { text: '', open: false },
      campaigns: [],
      audiences: [],
      softConversions: [],
      triggerSelectorUpdate: false,
      domainToConversions: {},
      device: [],
      destinationSubAccount: '',
      showCropWindow: false,
      browser: [],
      language: [],
      country: [],
      os: [],
      isBulk: this.props.isBulk || false,
      destinationAccount: ''
    };
  }
  createSortedList(metadata, key) {
    return Object.keys(metadata[key])
      .map(subKey => ({
        label: metadata[key][subKey],
        value: metadata[key][subKey]
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }

  async componentDidMount() {
    const metadata = await getOutbrainMetadata();
    const browser = this.createSortedList(metadata, 'browser');
    const country = Object.keys(metadata.country)
      .map(idx => ({
        label: metadata.country[idx].toLowerCase(),
        value: metadata.country[idx].toLowerCase()
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    const language = this.createSortedList(metadata, 'language');
    const os = this.createSortedList(metadata, 'os');

    this.setState({ browser, country, language, os });
    getSuggestedBids(PLATFORMS.OUTBRAIN.toLowerCase()).then(bids => {
      this.suggestedBidsOB = bids;
    });
    await this.updateUserOptions();

    if (!this.props.loadedFromSnapshot) {
      this._modifyOptions(OB.KEY_BUDGET, 'DAILY');
      this._modifyOptions(OB.KEY_BUDGET_AMOUNT, 30);
      this._modifyOptions(OB.KEY_MAX_CPC_LIMIT, '');
      this._modifyOptions(OB.KEY_CAMPAIGN_START_TIME, moment());
      this._modifyOptions(OB.KEY_CAMPAIGN_STOP_TIME, moment().set('year', moment().get('year') + 10));
      if (this.state.options.prefix === 'xx') {
        this._modifyOptions(OB.KEY_STATUS, OB.INACTIVE);
      } else {
        this._modifyOptions(OB.KEY_STATUS, OB.ACTIVE);
      }
      this._modifyOptions(OB.KEY_BID_TYPE, 'AUTOMATIC');
      // this.fillDomainsToConversion(OB.DEFAULT_SITE);
    }
  }

  async updateUserOptions() {
    if (!this.props.loadedFromSnapshot) {
      const userOpts = this.props.programOptions;
      let prefix = userOpts.spm_prefix_for_outbrain ? userOpts.spm_prefix_for_outbrain : userOpts.spm_prefix_default;
      this.modifyOptions(OB.KEY_CREATOR, getNewCreatorValue(userOpts.email, this.props.creatorsList));
      this.modifyOptions(OB.KEY_PREFIX, prefix);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.destinationAccount !== prevProps.destinationAccount) {
      this.updateSubAccounts();
    }

    if (this.props.sourceData !== prevProps.sourceData) {
      const newOptions = this.getSourceData(
        this.state.options,
        this.props.sourceData,
        this.props.articleList,
        this.props.platform,
        this.props.creatorsList,
        this.props.isBulk
      );
      this.setState(
        {
          options: newOptions,
          triggerSelectorUpdate: !this.state.triggerSelectorUpdate
        },
        () => {
          this.modifyBidsRows();
        }
      );
    }
    if (this.props.isBulk !== prevProps.isBulk) {
      this.setState({ isBulk: this.props.isBulk });
    }
    if (this.props.creativeFromFbLibrary) {
      this.props.creativeFbLibraryDone();
      console.log('creativeFromFbLibrary', this.props.creativeFromFbLibrary);
      this.addCreatives(this.props.creativeFromFbLibrary);
    }

    if (this.props.creative) {
      this.props.creativeDone();

      let parsedName;
      parsedName = parseCampaignName(PLATFORMS[this.props.creative.platform.toUpperCase()], this.props.creative.name);
      this.modifyOptions(OB.KEY_COUNTRY, parsedName.country || '');
      // this.modifyOptions(OB.KEY_OS, parsedName.country)
      this.modifyOptions(OB.KEY_SITE, parsedName.site || []);

      this.modifyOptions('widgetCodeName', parsedName.widgetCodeName || '');
      this.modifyOptions('widgetLanguageCode', parsedName.widgetLanguageCode || '');
      this.modifyOptions('widgetVersion', 'shz');

      if (parsedName.platform === 'd') {
        this.modifyOptions(OB.KEY_DEVICE, OB.DESKTOP);
      } else if (parsedName.platform === 'a') {
        this.modifyOptions(OB.KEY_DEVICE, [OB.DESKTOP, OB.MOBILE, OB.TABLET].join(','));
      } else if (parsedName.platform === 'm') {
        this.modifyOptions(OB.KEY_DEVICE, OB.MOBILE);
      } else {
        if (parsedName.platform && parsedName.platform.indexOf('adr') !== -1) {
          this.modifyOptions(OB.KEY_OS, 'Android');
          this.modifyOptions(OB.KEY_DEVICE, OB.MOBILE);
        }
        if (parsedName.platform && parsedName.platform.indexOf('ios') !== -1) {
          this.modifyOptions(OB.KEY_OS, 'iOS');
          this.modifyOptions(OB.KEY_DEVICE, OB.MOBILE);
        }
      }

      if (typeof this.props.creative.image_url === 'string') {
        this.addCreatives([
          {
            images: [this.props.creative.image_url],
            texts: [this.props.creative.text],
            titles: [this.props.creative.title || ''],
            descriptions: [this.props.creative.text]
          }
        ]);
      } else {
        this.addCreatives([
          {
            images: this.props.creative.image_url,
            texts: [this.props.creative.text],
            titles: [this.props.creative.title || ''],
            descriptions: [this.props.creative.text]
          }
        ]);
      }
    }
  }

  getSourceData(options, sourceData, articleList, platform, creatorsList, isBulk) {
    let parsed;
    if (isBulk) {
      parsed = parseBulkData(options, sourceData, articleList, platform);
    } else {
      parsed = parseDuplicateData(options, sourceData, articleList, platform, creatorsList);
    }
    console.log({ parsed });
    return parsed;
  }
  updateSubAccounts() {
    const destinationAccount = this.props.destinationAccount;
    if (destinationAccount) {
      // getOutbrainSubAccounts(destinationAccount).then(subAccounts => {
      //   this.setState({ subAccounts });
      // });
    } else {
      this.setState({ accounts: [] });
    }
  }

  getFullSite(domain) {
    const site = getPlatformSites(PLATFORMS.OUTBRAIN.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.name;
    }
    return undefined;
  }

  cancelCropWindow() {
    this.setState({ showCropWindow: false });
  }

  applyCropWindow(images) {
    images.map(image => {
      if (image.crops.Smartphone) image.crops.Smartphone.type = 'SMARTPHONE';

      return image;
    });
    this.addCreatives(this.state.creatives, images);
    this.setState({ showCropWindow: false });
  }
  validateOptions(key) {
    const value = this.state.options[key];
    switch (key) {
      case OB.KEY_DEFAULT_BID:
        if (value < OB.MIN_BID) {
          console.log('Min bid reached');
          this._modifyOptions(key, OB.MIN_BID);
        } else if (value > OB.MAX_BID) {
          console.log('Max bid reached');
          this._modifyOptions(key, OB.MAX_BID);
        }
        break;
      case OB.KEY_CONVERSION_RATE:
        if (value < OB.MIN_CONVERSION_BID) {
          console.log('Min bid reached');
          this._modifyOptions(key, OB.MIN_CONVERSION_BID);
        } else if (value > OB.MAX_CONVERSION_BID) {
          console.log('Max bid reached');
          this._modifyOptions(key, OB.MAX_CONVERSION_BID);
        }
        break;
      case OB.KEY_BUDGET_AMOUNT:
        if (value < OB.MIN_BUDGET) {
          console.log('Min budget reached');
          this._modifyOptions(key, OB.MIN_BUDGET);
        }
        break;
      default:
        throw new Error('Unexpected key in validateOptions' + key);
    }
  }

  _modifyOptions(key, value) {
    const options = this.state.options;
    options[key] = value;
    this.setState({
      options: options
    });
  }

  modifyOptions(key, value) {
    if (
      key === OB.KEY_BUDGET_AMOUNT ||
      key === OB.KEY_DEFAULT_BID ||
      key === OB.KEY_BID_CAP ||
      key === OB.KEY_MAX_CPC_LIMIT ||
      key === OB.KEY_QUERY_PARAMETERS ||
      key === OB.KEY_PREFIX ||
      key === 'campName' ||
      key === OB.KEY_CONVERSION_RATE
    ) {
      // It's an "input" element
      if (value.target) {
        value = value.target.value;
      }
    }
    if (key === OB.KEY_DEVICE && typeof value === 'string') {
      value = value.split(',');
    }

    this._modifyOptions(key, value);
    if (key === OB.KEY_DEVICE) {
      let devices = value;
      if (typeof devices === 'string') {
        devices = devices.split(',');
      }
      let newValue = [];
      if (
        !(
          devices.includes(OB.DESKTOP) &&
          (devices.includes(OB.MOBILE) || devices.includes(OB.TABLET)) &&
          this.state.options[OB.KEY_DEVICE_DUPLICATION_TYPE] === 'group'
        )
      ) {
        devices.forEach(dev => {
          if (OB.OS[dev]) {
            OB.OS[dev].forEach(os => {
              if (newValue.indexOf(os) === -1) {
                newValue.push(os);
              }
            });
          }
        });
      }
      this._modifyOptions(OB.KEY_OS_CHOICES, newValue);
      this._modifyOptions(
        OB.KEY_OS,
        newValue.filter(el => {
          return !OB.OS[OB.DESKTOP].includes(el);
        })
      );
    }
    if (
      key === OB.KEY_COUNTRY ||
      key === OB.KEY_DEVICE ||
      key === OB.KEY_OS ||
      key === OB.KEY_SITE ||
      key === OB.KEY_CONVERSION ||
      key === OB.KEY_DEVICE_DUPLICATION_TYPE ||
      key === OB.KEY_OS_DUPLICATION_TYPE ||
      key === GLOBAL.KEY_WIDGET_LANGUAGE_CODE
    ) {
      // Update bid rows
      this.modifyBidsRows();
    }
    if (key === OB.KEY_DEFAULT_BID) {
      this.setDefaultBid(value);
    }
    if (key === OB.KEY_SEMI_OPTIMIZATION) {
      if (value === 'off') {
        this._modifyOptions(OB.KEY_MAX_CPC_LIMIT, '');
        this._modifyOptions(OB.KEY_BID_STRATEGY, 'MAX_CONVERSION_FULLY_AUTOMATED');
        this._modifyOptions(OB.KEY_DEFAULT_BID, '');
        this.removeBid('');
      }
      if (value === 'on') {
        this._modifyOptions(OB.KEY_BID_CAP, '');
        this._modifyOptions(OB.KEY_MAX_CPC_LIMIT, '0.3');
        this._modifyOptions(OB.KEY_BID_STRATEGY, 'CPC');
        this._modifyOptions(OB.KEY_DEFAULT_BID, OB.DEFAULT_BID);
        this.setDefaultBid(OB.DEFAULT_BID);
      }
    }
    if (key === OB.KEY_CAMPAIGN_START_TIME_RANGE) {
      let startDate = this.state.options[OB.KEY_CAMPAIGN_START_TIME];
      const endDate = moment(this.state.options[OB.KEY_CAMPAIGN_START_TIME_RANGE]).add(1, 'day');
      let startDates = [];
      while (startDate <= endDate) {
        startDates.push(startDate);
        startDate = moment(startDate).add(1, 'day');
      }
      this._modifyOptions(OB.KEY_CAMPAIGN_START_DATES, startDates);
    }
  }

  modifyBidsRows() {
    if (!this.state.options[OB.KEY_COUNTRY] || !this.state.options[OB.KEY_DEVICE] || !this.state.options[OB.KEY_SITE]) {
      return;
    }
    // for some reason some of the values are arrays, some are strings
    //* in Select input - value type is string, when select all - array */
    let geos = this.state.options[OB.KEY_COUNTRY];
    if (typeof geos === 'string') {
      geos = geos.split(',');
    }
    let devices = this.state.options[OB.KEY_DEVICE];
    if (typeof devices === 'string') {
      devices = devices.split(',');
    }

    if (this.state.options[OB.KEY_DEVICE_DUPLICATION_TYPE] === 'group') {
      // This devices should be treated like a group
      devices = [devices.join(',')];
    }
    let oss = this.state.options[OB.KEY_OS] || [];
    if (typeof oss === 'string') {
      oss = oss.split(',');
    }
    if (this.state.options[OB.KEY_OS_DUPLICATION_TYPE] === 'group') {
      // This os should be treated like a group
      oss = [oss.join(',')];
    }
    let sites = this.state.options[OB.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    let newBids = [];
    let oldBids = this.state.options[OB.KEY_BIDS];
    geos.forEach(geo => {
      sites.forEach(site => {
        const conversions = OB.CONVERSIONS_LIST;
        const conversionName = this.state.options[OB.KEY_CONVERSION];
        let conversion = conversions ? conversions.find(el => el.value === conversionName) : '';
        conversion = conversion ? conversion.id : '';
        devices.forEach(dev => {
          const someOsMatch = oss.some(os => OB.OS[dev].includes(os));
          if (someOsMatch) {
            oss.forEach(os => {
              const osMatch = OB.OS[dev].includes(os);
              if (osMatch) {
                newBids.push({
                  country: geo,
                  device: dev,
                  site: site,
                  os: os,
                  fullSite: this.getFullSite(site),
                  bid: this.state.options[OB.KEY_DEFAULT_BID],
                  language: this.state.options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE],
                  conversion: conversion
                });
              }
            });
          } else {
            newBids.push({
              country: geo,
              device: dev,
              site: site,
              fullSite: this.getFullSite(site),
              bid: this.state.options[OB.KEY_DEFAULT_BID],
              language: this.state.options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE],
              conversion: conversion
            });
          }
        });
      });
    });

    newBids = newBids.map(newBid => {
      const old = oldBids.find(
        el =>
          el.country === newBid.country && el.device === newBid.device && el.os === newBid.os && el.site === newBid.site
      );
      if (old) {
        newBid.bid = old.bid;
      }
      return newBid;
    });
    this._modifyOptions(OB.KEY_BIDS, newBids);
  }

  modifyBid(idx, e) {
    const bid = e.target.value;
    let bids = this.state.options[OB.KEY_BIDS];
    bids[idx].bid = bid;
    this._modifyOptions(OB.KEY_BIDS, bids);
    let warningBids = bids.filter(el => {
      return el.bid > OB.MAX_WARN_BID;
    });
    this._modifyOptions('warnings', warningBids);
  }

  validateBid(idx) {
    let bids = this.state.options[OB.KEY_BIDS];
    const bid = bids[idx].bid;
    if (bid < OB.MIN_BID) {
      bids[idx].bid = OB.MIN_BID;
      this._modifyOptions(OB.KEY_BIDS, bids);
    } else if (bid > OB.MAX_BID) {
      bids[idx].bid = OB.DEFAULT_BID;
      this._modifyOptions(OB.KEY_BIDS, bids);
    }
  }

  setDefaultBid(val) {
    let bids = this.state.options[OB.KEY_BIDS];
    bids.forEach(b => {
      b.bid = val;
      if (val < OB.MIN_BID) {
        b.bid = OB.MIN_BID;
      } else if (val > OB.MAX_BID) {
        b.bid = OB.DEFAULT_BID;
      }
    });
    this._modifyOptions(OB.KEY_BIDS, bids);
  }

  removeBid(val) {
    let bids = this.state.options[OB.KEY_BIDS];
    bids.forEach(b => {
      b.bid = val;
      b.bid = '';
    });
    this._modifyOptions(OB.KEY_BIDS, bids);
  }
  onAddImage(groupIdx, e) {
    const images = Array.from(e.target.files);
    const errs = [];
    const warnings = [];

    const formData = new FormData();

    let basicValidatedImages = [];
    return Promise.all(
      images.map(file => {
        return getImageDimensions(file);
      })
    )
      .then(dimensions => {
        images.forEach((file, i) => {
          const filename_truncated = truncateFileName(file.name);

          if (!validateAndPrepareImage(file, filename_truncated, dimensions[i], groupIdx, 'OB', warnings, errs)) {
            return;
          }

          formData.append(i, file);
          basicValidatedImages.push(file);
        });

        return Promise.all(
          basicValidatedImages.map(file => {
            return getFileHash(file);
          })
        );
      })
      .then(() => {
        if (displayWarnings(errs, warnings)) return;
        const options = {
          minWidth: OB.MIN_WIDTH,
          minHeight: OB.MIN_HEIGHT
        };
        return storeFiles(formData, options);
      })
      .then(filenames => {
        if (filenames) {
          let groups = this.state.options[OB.KEY_CREATIVE_GROUPS];
          groups[groupIdx].images = groups[groupIdx].images.concat(filenames);
          this.modifyOptions(OB.KEY_CREATIVE_GROUPS, groups);
        }
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }

  onAddImages(groupIdx, imageBlob, deviceType, index) {
    const images = [imageBlob];
    const errs = [];
    const warnings = [];

    const formData = new FormData();
    let targetingDevice = '';
    let basicValidatedImages = [];
    const device = deviceType;

    let options = {};
    if (this.state.options.device) {
      if (typeof this.state.options.device === 'string') {
        targetingDevice = this.state.options.device;
      } else if (this.state.options.device[0]) {
        targetingDevice = this.state.options.device[0];
      }
    }
    return Promise.all(
      images.map(file => {
        return getImageDimensions(file);
      })
    )
      .then(dimensions => {
        if (device) {
          images.forEach((file, i) => {
            const filename_truncated = truncateFileName(file.name);

            if (
              !validateAndPrepareImage(
                file,
                filename_truncated,
                dimensions[i],
                groupIdx,
                'OB',
                warnings,
                errs,
                deviceType,
                options
              )
            ) {
              return;
            }

            formData.append(i, file, file.name);
            basicValidatedImages.push(file);
          });
        } else {
          warnings.push('Please, select the targeting device first.');
        }

        return Promise.all(
          basicValidatedImages.map(file => {
            return getFileHash(file);
          })
        );
      })
      .then(() => {
        if (displayWarnings(errs, warnings)) return;
        const scaledOptions = {
          minWidth: options.cropWidth,
          minHeight: options.cropHeight
        };
        return storeFiles(formData, scaledOptions);
      })
      .then(filenames => {
        if (filenames) {
          let groups = this.state.options[OB.KEY_CREATIVE_GROUPS];
          if (!groups[groupIdx].images[index]) {
            groups[groupIdx].images[index] = {};
          }
          groups[groupIdx].images[index] = filenames[0];
          groups[groupIdx].device = targetingDevice;
          this.modifyOptions(OB.KEY_CREATIVE_GROUPS, groups);
        }
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }

  async addCreatives(xs, images) {
    let adset = '';
    const groups = this.state.options[OB.KEY_CREATIVE_GROUPS];

    adset = { images: [], titles: [] };
    for (const [adsetIndex, x] of xs.entries()) {
      for (const title of x.titles) {
        for (const [imageIndex] of x.images.entries()) {
          const [image] = images.filter(img => img.adsetIndex === adsetIndex && img.imageIndex === imageIndex);
          const nonEmpyCrops = Object.values(image.crops).filter(c => c !== null);
          for (const crop of nonEmpyCrops) {
            adset.images.push(crop);
            adset.titles.push(title);
          }
        }
      }
    }

    const newGroup = {
      ...adset,
      images: [],
      tooltips: []
    };

    this.modifyOptions(OB.KEY_CREATIVE_GROUPS, [...groups, newGroup]);
    setTimeout(async () => {
      images.forEach(image => {
        for (const type in image.crops) {
          this.onAddImages(0, image.crops[type].blob, image.crops[type].type, image.imageIndex);
        }
      });
    });
  }

  onChangeImageTitle(groupIdx, imageIdx, e) {
    const title = e.target.value;
    let groups = this.state.options[OB.KEY_CREATIVE_GROUPS];
    groups[groupIdx].titles[imageIdx] = title;
    this.modifyOptions(OB.KEY_CREATIVE_GROUPS, groups);
  }

  onChangeImageDescription(groupIdx, imageIdx, e) {
    const description = e.target.value;
    let groups = this.state.options[OB.KEY_CREATIVE_GROUPS];
    groups[groupIdx].descriptions[imageIdx] = description;
    this.modifyOptions(OB.KEY_CREATIVE_GROUPS, groups);
  }

  removeImage(groupIdx, imageIdx) {
    let groups = this.state.options[OB.KEY_CREATIVE_GROUPS];
    groups[groupIdx].images.splice(imageIdx, 1);
    groups[groupIdx].titles.splice(imageIdx, 1);
    groups[groupIdx].descriptions.splice(imageIdx, 1);
    this.modifyOptions(OB.KEY_CREATIVE_GROUPS, groups);
  }

  duplicateImage(groupIdx, imageIdx) {
    let groups = this.state.options[OB.KEY_CREATIVE_GROUPS];
    const duplicatedImage = groups[groupIdx].images[imageIdx];
    const duplicatedText = groups[groupIdx].descriptions[imageIdx];
    const duplicateTitle = groups[groupIdx].titles[imageIdx];
    groups[groupIdx].images.push(duplicatedImage);
    groups[groupIdx].descriptions.push(duplicatedText);
    groups[groupIdx].titles.push(duplicateTitle);
    this.modifyOptions(OB.KEY_CREATIVE_GROUPS, groups);
  }

  removeGroup(groupIdx) {
    let groups = this.state.options[OB.KEY_CREATIVE_GROUPS];
    groups.splice(groupIdx, 1);
    this.modifyOptions(OB.KEY_CREATIVE_GROUPS, groups);
  }

  duplicateGroup(groupIdx) {
    let groups = this.state.options[OB.KEY_CREATIVE_GROUPS];
    // Deep copy group
    groups.push(JSON.parse(JSON.stringify(groups[groupIdx])));
    this.modifyOptions(OB.KEY_CREATIVE_GROUPS, groups);
  }

  populateText(groupIdx, imageIdx) {
    let groups = this.state.options[OB.KEY_CREATIVE_GROUPS];
    groups[groupIdx].images.forEach((_, idx) => {
      groups[groupIdx].descriptions[idx] = groups[groupIdx].descriptions[imageIdx];
      groups[groupIdx].titles[idx] = groups[groupIdx].titles[imageIdx];
    });
    this.modifyOptions(OB.KEY_CREATIVE_GROUPS, groups);
  }

  addCreativeGroup() {
    let groups = this.state.options[OB.KEY_CREATIVE_GROUPS] || [];
    groups.push({
      images: [],
      titles: [''],
      descriptions: ['']
    });
    this.modifyOptions(OB.KEY_CREATIVE_GROUPS, groups);
  }

  upsertSnapshot() {
    if (this.props.task === TASKS.CREATE) {
      this.props.upsertSnapshot(this.state.options);
    } else if (this.props.task === TASKS.DUPLICATE) {
      this.props.upsertSnapshot();
    }
  }

  startCreateCampaign() {
    const options = this.state.options;
    const preparedOptions = this.prepareOptions(options);
    console.log({ preparedOptions });
    if (this.props.task === TASKS.CREATE) {
      this.props.createCampaign(preparedOptions);
    } else if (this.props.task === TASKS.DUPLICATE) {
      this.props.createCampaign(preparedOptions);
    }
  }

  constructCreativeGroups(creativeGroups) {
    return creativeGroups.map(group => ({
      item: group.images.map((image, index) => {
        let item = {
          image,
          title: group.titles[index]
        };

        if (group.descriptions[index]) {
          item.text = group.descriptions[index];
        }

        return item;
      })
    }));
  }

  prepareOptions(options) {
    const payload = { campaign: [] };
    const opts = this.state.options;
    if (!this.state.isBulk) {
      payload.campaign.push(this.prepareCreateOptions(opts));
    } else {
      const bulkOptions = this.prepareBulkOptions(options);
      payload.campaign.push(...bulkOptions.map(option => this.prepareCreateOptions(option)));
    }
    if (this.state.destinationAccount && this.state.destinationAccount.length > 0) {
      let result = {
        account: [
          {
            account_id: this.state.destinationAccount,
            campaign: payload.campaign
          }
        ]
      };
      console.log({ result });
      return result;
    } else {
      console.log({ payload });
      return payload;
    }
  }

  prepareBulkOptions() {
    const { options } = this.state;
    const {
      creativeGroups,
      [OB.KEY_ORIGINAL_SOURCE_DATA]: sourceCampaigns,
      campaignCreator,
      ...otherOptions
    } = options;
    let preparedCampaigns;

    if (sourceCampaigns.length === 1) {
      preparedCampaigns = sourceCampaigns.map(sourceCampaign => {
        return {
          ...otherOptions,
          ...sourceCampaign,
          creativeGroups,
          campaignCreator,
          singleSourceCampaign: true
        };
      });
    } else {
      preparedCampaigns = sourceCampaigns.map(sourceCampaign => ({
        ...otherOptions,
        ...sourceCampaign
      }));
    }

    return preparedCampaigns;
  }

  prepareCreateOptions(options) {
    const { isBulk } = this.state;
    let payload = {
      maxCpcBidPercentage: options.maxCpcBidPercentage,
      automaticModeMaxCpc: options.automaticModeMaxCpc,
      budget_type: options.budget,
      budget: options.budget_amount,
      bid_type: options.bid_type,
      bid_strategy: options.bid_strategy,
      prefix: options.prefix,
      article: isBulk ? options.article : options.fullCodeName,
      status: options.status.toUpperCase(),
      campaign_start: options.campaign_start_time,
      campaign_stop: options.campaign_stop_time ? options.campaign_stop_time : undefined,
      conversion:
        options.softConversions && options.softConversions.length > 0
          ? [].concat(options.conversion, options.softConversions)
          : [options.conversion],
      creator: options.campaignCreator,
      free_text: options.freeText || '',
      note: options.campaignNote || '',
      semiOptimization: options.semiOptimization,
      isBulk: isBulk,
      task: this.props.task,
      __split: [
        isBulk && !options.singleSourceCampaign
          ? [{ item: options.creativeGroups }]
          : this.constructCreativeGroups(options.creativeGroups),
        options.bids.map(b => ({
          country: b.country,
          language: isBulk ? options.language : b.language,
          device: b.device,
          os: b.os,
          browser: options.browser,
          site: b.site,
          bid: b.bid,
          conversion:
            options.softConversions && options.softConversions.length > 0
              ? [].concat(options.conversion, options.softConversions)
              : [options.conversion]
        }))
      ]
    };
    console.log({ payload });
    return payload;
  }

  getCampaignsNumber() {
    return this.state.options[OB.KEY_CAMPAIGN_START_DATES] && this.state.options[OB.KEY_CAMPAIGN_START_DATES].length > 1
      ? this.state.options[OB.KEY_CAMPAIGN_START_DATES].length *
          this.state.options[OB.KEY_BIDS].length *
          this.state.options[OB.KEY_CREATIVE_GROUPS].length
      : this.state.options[OB.KEY_BIDS].length * this.state.options[OB.KEY_CREATIVE_GROUPS].length;
  }

  getCreateButtonText() {
    const campaignsNumber = this.getCampaignsNumber();
    const displayCampaignsNumber = campaignsNumber || '';
    switch (this.props.task) {
      case TASKS.CREATE:
        return `Create ${displayCampaignsNumber} campaigns`;
      case TASKS.DUPLICATE:
        return `Duplicate campaign to ${displayCampaignsNumber} new campaigns`;
      default:
        return 'Something is wrong';
    }
  }

  getAllowedWidgetVersions() {
    // If some selected domain allows only a subset of widget versions, return it instead of all allowed versions
    let sites = this.state.options[OB.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    for (const s of sites) {
      if (OB.SITE_ALLOWED_VERSIONS[s]) {
        return OB.SITE_ALLOWED_VERSIONS[s];
      }
    }
    return OB.ALLOWED_VERSIONS;
  }

  renderRowName(bid) {
    const parts = [bid.country, bid.device, bid.os, bid.site];
    return parts.filter(el => el).join('-') + ' = ' + bid.bid;
  }

  validateSetup() {
    // Check that we have campaigns to create
    if (!this.state.isBulk && !this.getCampaignsNumber()) {
      const err = 'Select all required fields';
      console.warn(err);
      toast.error(err);
      return false;
    }

    if (!this.state.isBulk && !this.state.options.widgetid) {
      const err = 'widget id is empty';
      console.warn(err);
      toast.error(err);
      return false;
    }
    // Check that we have selected conversions
    if (
      (this.state.options[OB.KEY_BID_STRATEGY] === 'CPC' ||
        this.state.options[OB.KEY_BID_STRATEGY] === 'MAX_CONVERSION_FULLY_AUTOMATED') &&
      !this.state.options[OB.KEY_CONVERSION]
    ) {
      const err = 'There are no selected conversion';
      console.log(err);
      toast.error(err);
      return false;
    }
    if (
      this.state.options[OB.KEY_BID_STRATEGY] === 'MAX_CONVERSION_FULLY_AUTOMATED' &&
      !this.state.options[OB.KEY_BID_CAP]
    ) {
      const err = 'Empty bid cap. Please add the value';
      console.log(err);
      toast.error(err);
      return false;
    }
    return true;
  }

  constructCampaignName(options) {
    let oldNaming = {};
    let newCampaignIndex = 1;
    if (options.sourceName) {
      oldNaming = parseCampaignName(PLATFORMS.OUTBRAIN, options.sourceName);
    }
    const PLATFORM_DICT = {
      desktop: 'd',
      mobile: 'm',
      tablet: 't',
      all: 'a'
    };

    const OS_DICT = {
      android: 'adr',
      ios: 'ios',
      macos: 'mos',
      windows: 'win'
    };

    const parts = [];
    parts.push(options.prefix || oldNaming.prefix);
    let countryPart = oldNaming.country;
    if (options.country) {
      if (options.country.indexOf(',') !== -1) {
        // It's a group geo, set name to ww
        countryPart = 'ww';
      } else {
        countryPart = options.country;
      }
    }
    parts.push(countryPart);
    let platform = 'd';
    if (typeof options.device !== 'string' && options.device) {
      options.device = options.device[0] ? options.device[0] : '';
    }
    if (options.device && PLATFORM_DICT[options.device]) {
      platform = PLATFORM_DICT[options.device];
    }
    parts.push(platform || oldNaming.platform);
    if (options.site && this.props.platform === PLATFORMS.OUTBRAIN) {
      if (typeof options.site === 'string') {
        options.site = options.site.split(',');
      }
      parts.push(options.site[0].toLowerCase() || oldNaming.site);
    }
    if (options.widgetCodeName && options.widgetLanguageCode) {
      parts.push(options.widgetCodeName + options.widgetLanguageCode || oldNaming.article || 'ARTICLE');
    } else {
      // We're duplicating campaign without specifying the new article/offer
      // So copy the original one
      parts.push(oldNaming.article);
    }
    if (typeof options.os === 'string') {
      options.os = options.os.split(',');
    }
    if (
      options.device &&
      !options.device.includes('desktop') &&
      options.os &&
      options.os[0] &&
      this.props.platform === PLATFORMS.OUTBRAIN
    ) {
      parts.push(OS_DICT[options.os[0]]);
    }
    parts.push(getCreationDate());
    if (this.state.campaigns[0]) {
      newCampaignIndex = getMaxCampaignIndex(this.state.campaigns.map(c => c.desc)) + this.getCampaignsNumber();
    }
    parts.push('c' + newCampaignIndex);
    if (this.state.options.freeText) {
      parts.push(this.state.options.freeText);
    }
    return parts.join('-');
  }

  onChangeFreeText(e) {
    let text = e.target.value;
    this._modifyOptions('freeText', text);
    const newCampName = this.constructCampaignName(this.state.options);
    let tooltips;
    const charsLeft = OB.MAX_CAMP_NAME_LENGTH - newCampName.length;
    tooltips = {
      text: `Chars left: ${charsLeft}`,
      open: true
    };
    const nonLetterNumber = /[^a-z0-9]+/i;
    if (text.match(nonLetterNumber)) {
      tooltips.text = 'Only letters and numbers';
      text = text.replace(nonLetterNumber, '');
      this._modifyOptions('freeText', text);
      this.setState({ tooltips });
      return;
    }
    if (charsLeft < 0) {
      text = text.slice(0, -1);
      tooltips.text = `Chars left: 0`;
    }
    this._modifyOptions('freeText', text);
    this.setState({ tooltips: tooltips });
  }

  handlePaste = event => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text').replace(',', '.');
    let value = parseFloat(pastedData);

    if (value > OB.MAX_BID) {
      this.modifyOptions(OB.KEY_BID_CAP, OB.DEFAULT_BID);
    } else {
      this.modifyOptions(OB.KEY_BID_CAP, value);
    }
  };

  onBlurFreeText() {
    let tooltips;
    tooltips = {
      open: false
    };
    this.setState({ tooltips: tooltips });
  }

  countCampaigns = options => {
    let campaigns = options && options.account ? options.account[0].campaign : options.campaign;

    return campaigns.reduce((count, campaign) => {
      if (Array.isArray(campaign.__split) && Array.isArray(campaign.__split[1])) {
        count += campaign.__split[1].length;
      }
      return count;
    }, 0);
  };

  isEmptyCreatives(options) {
    let campaigns = options && options.account ? options.account[0].campaign : options.campaign;

    return campaigns.every(camp => {
      const isValid = camp.__split[0]?.length && camp.__split[0].every(item => item && Object.keys(item).length > 0);

      if (!isValid) {
        const warningMessage = GLOBAL.ERRORS.EMPTY_CREATIVE_GROUPS + ' in campaign: ' + camp.duplicate;
        console.warn(warningMessage);
        toast.error(warningMessage);
        return;
      }

      return isValid;
    });
  }

  isEmptyArticle(options) {
    let campaigns = options && options.account ? options.account[0].campaign : options.campaign;

    return campaigns.every(camp => {
      const isValid = camp.article;
      if (!isValid) {
        console.warn(GLOBAL.ERRORS.EMPTY_ARTICLE + ' in campaign: ' + camp.duplicate);
        toast.error(GLOBAL.ERRORS.EMPTY_ARTICLE + ' in campaign: ' + camp.duplicate);
        return;
      }

      return isValid;
    });
  }

  isEmptyCreator(options) {
    let campaigns = options && options.account ? options.account[0].campaign : options.campaign;

    return campaigns.every(camp => {
      const isValid = camp.creator;
      if (!isValid) {
        console.warn(GLOBAL.ERRORS.EMPTY_CREATOR + ' in campaign: ' + camp.duplicate);
        toast.error(GLOBAL.ERRORS.EMPTY_CREATOR + ' in campaign: ' + camp.duplicate);
        return;
      }

      return isValid;
    });
  }

  updateDestinationAccount(acc) {
    const { accounts, siteList } = this.props;
    const selectedSubAccount = accounts.find(x => x.id === acc);
    const autoselectItem = selectedSubAccount?.autoselect[0];
    if (autoselectItem) {
      let { site, device } = autoselectItem;
      device = this.getDeviceNames(device);
      let siteInList = siteList.some(siteObj => siteObj.value === site);
      if (siteInList) {
        this.updateOption(OB.KEY_SITE, site);
      } else {
        this.modifyOptions(OB.KEY_SITE, []);
      }
      if (siteList.includes(site)) {
        this.updateOption(OB.KEY_SITE, site);
      }

      this.updateOption(OB.KEY_DEVICE, device);
    }
    this.setState({ destinationAccount: acc, randomizeAccount: false });
  }

  getDeviceNames(deviceSymbol) {
    const deviceNames = {
      d: 'DESKTOP',
      m: 'MOBILE',
      t: 'TABLET'
    };

    if (deviceSymbol) {
      return deviceNames[deviceSymbol];
    } else {
      return undefined;
    }
  }

  updateOption(key, value) {
    if (value) {
      this.modifyOptions(key, [value]);
    }
  }

  getDestinationAccountOptions() {
    return this.props.accounts
      .map(el => {
        return { label: el.desc, value: el.id };
      })
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }

  submit() {
    let options = this.prepareOptions(this.state.options);
    let title = '';
    let text = null;

    if (!this.validateSetup()) {
      return;
    }

    if (this.state.isBulk) {
      //just show the error message
      this.isEmptyCreatives(options);

      if (!this.isEmptyArticle(options)) {
        return;
      }
      if (!this.isEmptyCreator(options)) {
        return;
      }
    }

    const totalCount = this.state.isBulk ? this.countCampaigns(options) : this.state.options.bids.length;
    if (this.state.options.warnings && this.state.options.warnings.length > 0) {
      const warningBids = this.state.options.warnings;
      let warn = [];
      warningBids.forEach(el => {
        warn.push(this.renderRowName(el));
      });
      warn.push('end');
      title = 'You have high bids:';
      text = warn.map((el, idx) => {
        if (el === 'end') {
          return (
            <p key={idx}>
              <br />
              {'Are you sure to do this?'}
            </p>
          );
        }
        return <p key={idx}>{el}</p>;
      });
    } else {
      title = this.getCreateButtonText();
      text = this.state.isBulk
        ? `You will create ${totalCount} campaigns. Are you sure you want to do this?`
        : ` Are you sure you want to do this?`;
    }

    confirmAlert({
      title: title,
      message: text,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.startCreateCampaign()
        },
        {
          label: 'No',
          onClick: () => this.prepareOptions(this.state.options)
        }
      ]
    });
  }

  getSuggestedBid(bid) {
    try {
      const bidDevice = bid.device.length > 0 ? bid.device[0].toLowerCase() : 'unknown';
      const bidSite = bid.site ? bid.site : 'all';
      const bidLanguage =
        bid.country === 'us' && bidDevice === 'm' && this.state.options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE]
          ? this.state.options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE]
          : 'all';
      const bidOS = bid.os === OB.ANDROID ? 'adr' : bid.os === OB.IOS ? 'ios' : 'all';

      const suggestedBids = this.suggestedBidsOB;

      const filteredBids = suggestedBids.filter(
        x =>
          x.geo === bid.country &&
          x.device === bidDevice &&
          x.site === bidSite &&
          x.language === bidLanguage &&
          x.os === bidOS
      );

      if (filteredBids.length > 0) {
        return Math.max(parseFloat(filteredBids[0].epc), OB.MIN_BID);
      } else {
        return 'unknown';
      }
    } catch (e) {
      console.error(`can't get suggested bid for ${JSON.stringify(bid, null, 2)}`, e);
      Sentry.captureException(e);
      return 'unknown';
    }
  }

  render() {
    return (
      <>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Destination account
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.updateDestinationAccount(x?.value)}
            options={this.getDestinationAccountOptions()}
            isClearable
            value={getSelectValue(this.getDestinationAccountOptions(), this.state.destinationAccount)}
          />
        </Grid>
        <Prefix
          value={{ label: this.state.options[OB.KEY_PREFIX], value: this.state.options[OB.KEY_PREFIX] }}
          onChange={x => this.modifyOptions(OB.KEY_PREFIX, x.value)}
          options={makeSelectOptions(this.props.programOptions?.prefix)}
        />
        {!this.state.isBulk || (this.state.isBulk && this.state.options.original_source_data?.length === 1) ? (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Creator
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                value={getSelectValue(this.props.creatorsList, this.state.options[OB.KEY_CREATOR])}
                onChange={x => this.modifyOptions(OB.KEY_CREATOR, x?.value)}
                options={this.props.creatorsList}
              />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select geo
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(OB.KEY_COUNTRY, getOnlyValue(xs))}
            options={this.state.country}
            value={getMultiSelectValue(this.state.country, this.state.options[OB.KEY_COUNTRY])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select site
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(OB.KEY_SITE, getOnlyValue(xs))}
            options={this.props.siteList}
            value={getMultiSelectValue(this.props.siteList, this.state.options[OB.KEY_SITE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select device
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(OB.KEY_DEVICE, getOnlyValue(xs))}
            options={OB.DEVICES}
            value={getMultiSelectValue(OB.DEVICES, this.state.options[OB.KEY_DEVICE])}
          />
        </Grid>
        <Grid item xs={6} sm={3} align={'center'}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.options[OB.KEY_DEVICE_DUPLICATION_TYPE] === 'group'}
                onChange={(e, value) =>
                  this.modifyOptions(OB.KEY_DEVICE_DUPLICATION_TYPE, value ? 'group' : 'separate')
                }
                color="primary"
              />
            }
            label={
              this.state.options[OB.KEY_DEVICE_DUPLICATION_TYPE] === 'group' ? (
                <Typography gutterBottom variant={'subtitle2'}>
                  Group
                </Typography>
              ) : (
                <Typography gutterBottom variant={'subtitle2'}>
                  Separate
                </Typography>
              )
            }
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select OS
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Select
            isClearable
            isMulti
            onChange={xs => this.modifyOptions(OB.KEY_OS, getOnlyValue(xs))}
            options={makeSelectOptions(this.state.options[OB.KEY_OS_CHOICES])}
            value={getMultiSelectValue(
              makeSelectOptions(this.state.options[OB.KEY_OS_CHOICES]),
              this.state.options[OB.KEY_OS]
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3} align={'center'}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.options[OB.KEY_OS_DUPLICATION_TYPE] === 'group'}
                onChange={(e, value) => this.modifyOptions(OB.KEY_OS_DUPLICATION_TYPE, value ? 'group' : 'separate')}
                color="primary"
              />
            }
            label={
              this.state.options[OB.KEY_OS_DUPLICATION_TYPE] === 'group' ? (
                <Typography gutterBottom variant={'subtitle2'}>
                  Group
                </Typography>
              ) : (
                <Typography gutterBottom variant={'subtitle2'}>
                  Separate
                </Typography>
              )
            }
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
        {/*<Grid item xs={6} sm={3} align={'center'}>*/}
        {/*  <FormControlLabel*/}
        {/*    control={*/}
        {/*      <Switch*/}
        {/*        checked={this.state.options[OB.KEY_OS_DUPLICATION_TYPE] === 'group'}*/}
        {/*        onChange={(e, value) => this.modifyOptions(OB.KEY_OS_DUPLICATION_TYPE, value ? 'group' : 'separate')}*/}
        {/*        color="primary"*/}
        {/*      />*/}
        {/*    }*/}
        {/*    label="Group"*/}
        {/*    labelPlacement="start"*/}
        {/*    style={{ marginLeft: 'auto' }}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select browser
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isClearable
            isMulti
            onChange={xs => this.modifyOptions(OB.KEY_BROWSER, getOnlyValue(xs))}
            options={this.state.browser}
            value={getMultiSelectValue(this.state.browser, this.state.options[OB.KEY_BROWSER])}
          />
        </Grid>
        {/*<Grid item xs={12} sm={4}>*/}
        {/*  <Typography gutterBottom variant={'subtitle2'}>*/}
        {/*    Example campaign name*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} sm={8}>*/}
        {/*  <Typography gutterBottom variant={'subtitle2'}>*/}
        {/*    {exampleName}*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select interests
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isClearable
            isMulti
            onChange={xs => this.modifyOptions(OB.KEY_INTERESTS, getOnlyValue(xs))}
            options={this.props.interestsList}
            value={getMultiSelectValue(this.props.interestsList, this.state.options[OB.KEY_INTERESTS])}
          />
        </Grid>
        {!this.state.isBulk && (
          <>
            <WidgetSelector
              modify={(key, value) => this.modifyOptions(key, value)}
              articleList={this.props.articleList}
              options={this.state.options}
              allowedVersions={this.getAllowedWidgetVersions()}
              defaultVersion={OB.DEFAULT_VERSION}
              triggerSelectorUpdate={this.state.triggerSelectorUpdate}
              programOptions={this.props.programOptions}
            />
          </>
        )}
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Bid strategy
          </Typography>
        </Grid>
        {/*<Grid item xs={12} sm={8}>*/}
        {/*  <Select*/}
        {/*    onChange={x => this.modifyOptions(OB.KEY_BID_STRATEGY, x.value)}*/}
        {/*    options={OB.BID_STRATEGY_LIST}*/}
        {/*    value={getSelectValue(OB.BID_STRATEGY_LIST, this.state.options[OB.KEY_BID_STRATEGY])}*/}
        {/*  />*/}
        {/*</Grid>*/}

        {/*  We will hide options and will show only one available for users, meanwhile we will have more options for the creation*/}
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(OB.KEY_BID_STRATEGY_UI, x.value)}
            options={OB.DEFAULT_BID_STRATEGY}
            value={getSelectValue(OB.DEFAULT_BID_STRATEGY, this.state.options[OB.KEY_BID_STRATEGY_UI])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Conversions
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(OB.KEY_CONVERSION, x?.value)}
            options={OB.CONVERSIONS_LIST}
            value={getSelectValue(OB.CONVERSIONS_LIST, this.state.options[OB.KEY_CONVERSION])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Soft conversions
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isClearable
            isMulti
            onChange={xs => this.modifyOptions(OB.KEY_SOFT_CONVERSIONS, getOnlyValue(xs))}
            options={OB.CONVERSIONS_LIST}
            value={getMultiSelectValue(OB.CONVERSIONS_LIST, this.state.options[OB.KEY_SOFT_CONVERSIONS])}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Semi optimization
          </Typography>
        </Grid>
        <Grid item xs={6} sm={8} align={'center'}>
          <Stack direction="row" spacing={1} alignItems="center" style={{ marginLeft: 'auto' }}>
            <Typography gutterBottom variant={'subtitle2'}>
              Off
            </Typography>
            <Switch
              checked={this.state.options[OB.KEY_SEMI_OPTIMIZATION] === 'on'}
              onChange={(e, value) => this.modifyOptions(OB.KEY_SEMI_OPTIMIZATION, value ? 'on' : 'off')}
              color="primary"
            />
            <Typography gutterBottom variant={'subtitle2'}>
              On
            </Typography>
          </Stack>
        </Grid>

        {/*conversions CPC bid strategy when semi optimization is on*/}
        {/*{this.state.options[OB.KEY_BID_STRATEGY] === 'CPC' && (*/}
        {this.state.options[OB.KEY_SEMI_OPTIMIZATION] === 'on' && (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Max CPC limit
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8}>
              <input
                type="number"
                name={OB.KEY_MAX_CPC_LIMIT}
                step={OB.BID_STEP}
                min={'0.3'}
                value={this.state.options[OB.KEY_MAX_CPC_LIMIT]}
                onChange={value => this.modifyOptions(OB.KEY_MAX_CPC_LIMIT, value)}
                onBlur={event => {
                  const value = event.target.value;
                  if (value && parseFloat(value) < 0.3) {
                    this.modifyOptions(OB.KEY_MAX_CPC_LIMIT, '0.3');
                  }
                }}
                placeholder="Enter a number greater than or equal to 0.3"
              />
            </Grid>
            <Grid item xs={12} sm={12} align="center">
              <Divider />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Default bid budget
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <input
                type="number"
                name={OB.KEY_DEFAULT_BID}
                step={OB.BID_STEP}
                value={this.state.options[OB.KEY_DEFAULT_BID]}
                onChange={value => this.modifyOptions(OB.KEY_DEFAULT_BID, value)}
                onBlur={value => this.validateOptions(OB.KEY_DEFAULT_BID, value)}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              Set all suggested bids
            </Grid>
            <OutbrainBids
              bids={this.state.options[OB.KEY_BIDS]}
              modifyBid={(idx, e) => this.modifyBid(idx, e)}
              validateBid={idx => this.validateBid(idx)}
              getSuggestedBid={b => this.getSuggestedBid(b)}
            />
            <Grid item xs={12} sm={12} align="center">
              <Divider />
            </Grid>
          </>
        )}
        {/*max conversions when semi optimization is off*/}
        {/*{this.state.options[OB.KEY_BID_STRATEGY] === 'MAX_CONVERSION_FULLY_AUTOMATED' && (*/}
        {this.state.options[OB.KEY_SEMI_OPTIMIZATION] === 'off' && (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Bid cap
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8}>
              <input
                type="number"
                name={OB.KEY_BID_CAP}
                step={OB.BID_STEP}
                value={this.state.options[OB.KEY_BID_CAP]}
                onPaste={e => this.handlePaste(e)}
                onChange={e => {
                  let value = parseFloat(e.target.value.replace(',', '.'));
                  if (value > OB.MAX_BID) {
                    this.modifyOptions(OB.KEY_BID_CAP, OB.DEFAULT_BID);
                  } else {
                    this.modifyOptions(OB.KEY_BID_CAP, value);
                  }
                }}
              />
            </Grid>
          </>
        )}

        <>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Audiences
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              isMulti
              onChange={xs => this.modifyOptions(OB.KEY_AUDIENCE, getOnlyValue(xs))}
              options={this.state.audiences}
              value={getMultiSelectValue(this.state.audiences, this.state.options[OB.KEY_AUDIENCE])}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Budget type
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              onChange={x => this.modifyOptions(OB.KEY_BUDGET, x?.value)}
              options={OB.SPENDING_MODEL_LIST}
              value={getSelectValue(OB.SPENDING_MODEL_LIST, this.state.options[OB.KEY_BUDGET])}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Budget
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <input
              type="number"
              name={OB.KEY_BUDGET_AMOUNT}
              min={OB.MIN_BUDGET}
              value={this.state.options[OB.KEY_BUDGET_AMOUNT] || 0}
              onChange={value => this.modifyOptions(OB.KEY_BUDGET_AMOUNT, value)}
              onBlur={value => this.validateOptions(OB.KEY_BUDGET_AMOUNT, value)}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Pacing Type
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              onChange={x => this.modifyOptions(OB.KEY_BID_TYPE, x?.value)}
              options={OB.BID_TYPE_LIST}
              value={getSelectValue(OB.BID_TYPE_LIST, this.state.options[OB.KEY_BID_TYPE])}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Campaign start date
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DatePicker
              startDate={this.state.options[OB.KEY_CAMPAIGN_START_TIME]}
              endDate={this.state.options[OB.KEY_CAMPAIGN_START_TIME_RANGE]}
              selected={
                this.state.options[OB.KEY_CAMPAIGN_START_TIME]
                  ? new Date(this.state.options[OB.KEY_CAMPAIGN_START_TIME])
                  : ''
              }
              onChange={value => this.modifyOptions(OB.KEY_CAMPAIGN_START_TIME, value)}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Campaign end date
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DatePicker
              startDate={this.state.options[OB.KEY_CAMPAIGN_START_TIME]}
              endDate={this.state.options[OB.KEY_CAMPAIGN_START_TIME_RANGE]}
              selected={
                this.state.options[OB.KEY_CAMPAIGN_START_TIME_RANGE]
                  ? new Date(this.state.options[OB.KEY_CAMPAIGN_START_TIME_RANGE])
                  : ''
              }
              onChange={value => this.modifyOptions(OB.KEY_CAMPAIGN_START_TIME_RANGE, value)}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              onChange={x => this.modifyOptions(OB.KEY_STATUS, x?.value)}
              options={OB.STATUS}
              value={getSelectValue(OB.STATUS, this.state.options[OB.KEY_STATUS])}
            />
          </Grid>

          <CampaignsNote
            value={this.state.options[GLOBAL.KEY_CAMPAIGN_NOTE]}
            onChange={e => {
              this._modifyOptions(GLOBAL.KEY_CAMPAIGN_NOTE, e.target.value);
            }}
          />
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant={'subtitle2'}>
              Free text
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Tooltip key={'tooltip'} title={this.state.tooltips ? this.state.tooltips.text : ''}>
              <textarea
                key={'freeText'}
                value={this.state.options.freeText}
                onChange={e => this.onChangeFreeText(e)}
                onBlur={() => this.onBlurFreeText()}
                maxLength={40}
                rows="1"
                style={{
                  resize: 'none'
                }}
              />
            </Tooltip>
          </Grid>
          {!this.state.isBulk || (this.state.isBulk && this.state.options.original_source_data?.length === 1) ? (
            <>
              <Grid item xs={12} sm={4}>
                <Typography gutterBottom variant={'subtitle2'}>
                  Creative Groups:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} align="right"></Grid>
              <Grid item xs={6} sm={4} align="right">
                <Button fullWidth variant="contained" color="primary" onClick={() => this.addCreativeGroup()}>
                  Add group
                </Button>
              </Grid>
              <OutbrainCreativeGroupBuilder
                groups={this.state.options[OB.KEY_CREATIVE_GROUPS]}
                onAddImage={(idx, e) => this.onAddImage(idx, e)}
                onAddImages={(groupIdx, imageBlob, deviceType, index) =>
                  this.onAddImages(groupIdx, imageBlob, deviceType, index)
                }
                onChangeImageTitle={(groupIdx, imageIdx, e) => this.onChangeImageTitle(groupIdx, imageIdx, e)}
                onChangeImageDescription={(groupIdx, imageIdx, e) =>
                  this.onChangeImageDescription(groupIdx, imageIdx, e)
                }
                removeImage={(groupIdx, imageIdx) => this.removeImage(groupIdx, imageIdx)}
                duplicateImage={(groupIdx, imageIdx) => this.duplicateImage(groupIdx, imageIdx)}
                removeGroup={idx => this.removeGroup(idx)}
                duplicateGroup={idx => this.duplicateGroup(idx)}
                populateText={(groupIdx, imageIdx) => this.populateText(groupIdx, imageIdx)}
              />
            </>
          ) : null}
        </>
        <Grid item xs={12} sm={4} align="right"></Grid>
        {/*{this.state.showCropWindow ? (*/}
        {/*  <CropWindow*/}
        {/*    desktopAspectRatio={OB.DESKTOP_ASPECT_RATIO}*/}
        {/*    smartphoneAspectRatio={OB.SMARTPHONE_ASPECT_RATIO}*/}
        {/*    data={this.state.creatives}*/}
        {/*    onlyOneCropRequired={true}*/}
        {/*    onClose={() => this.cancelCropWindow()}*/}
        {/*    onSave={images => this.applyCropWindow(images)}*/}
        {/*    fromInsights={true}*/}
        {/*    platform={PLATFORMS.OUTBRAIN}*/}
        {/*  />*/}
        {/*) : null}*/}
        <Grid item xs={6} sm={4} align="right">
          <Button variant="outlined" fullWidth color="primary" onClick={() => this.upsertSnapshot()}>
            {this.props.loadedFromSnapshot ? 'Update' : 'Save'} snapshot
          </Button>
        </Grid>
        <Grid item xs={6} sm={4} align="right">
          <Button variant="contained" fullWidth color="primary" onClick={() => this.submit()}>
            {this.getCreateButtonText()}
          </Button>
        </Grid>
      </>
    );
  }
}

function formCreativeGroup(adsData) {
  const group = {
    images: [],
    descriptions: [],
    titles: []
  };
  if (Array.isArray(adsData)) {
    adsData.forEach(ad => {
      group.images.push(ad.image);
      group.descriptions.push(ad.text);
      group.titles.push(ad.title);
    });
  } else {
    group.images.push(adsData.image);
    group.descriptions.push(adsData.text);
    group.titles.push(adsData.title);
  }
  return group;
}

function parseDuplicateData(options, sourceData) {
  console.log({ sourceData });
  let data = sourceData.account.campaign;
  options[OB.KEY_CREATIVE_GROUPS] = data.item.map(formCreativeGroup);
  options[OB.KEY_BIDS] = [];
  const parsedName = parseCampaignName(PLATFORMS.OUTBRAIN, data._name);
  options[GLOBAL.KEY_WIDGET_CODE_NAME] = parsedName.widgetCodeName;
  options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE] = parsedName.widgetLanguageCode;
  options[GLOBAL.KEY_CAMPAIGN_NOTE] = data.note;
  options[OB.KEY_CREATOR] = data.creator;
  options[OB.KEY_SITE] = [parsedName.site];
  options[OB.KEY_COUNTRY] = [parsedName.country];
  options[OB.KEY_DEVICE] = [];

  const title = 'Incorrect source campaign name.';
  const text = `Unknown device "${parsedName.device}"`;
  switch (parsedName.device) {
    case 'd':
      options[OB.KEY_DEVICE].push(OB.DESKTOP);
      break;
    case 'm':
      options[OB.KEY_DEVICE].push(OB.MOBILE);
      break;
    case 't':
      options[OB.KEY_DEVICE].push(OB.TABLET);
      break;
    case 'a':
      options[OB.KEY_DEVICE].push(OB.ALL);
      break;
    default:
      options[OB.KEY_DEVICE].push(null);

      confirmAlert({
        title: title,
        message: text,
        buttons: [
          {
            label: 'OK'
          }
        ]
      });
  }
  options[OB.KEY_OS_CHOICES] = OB.OS[data.device];
  options[OB.KEY_BUDGET] = data.budget_type;
  options[OB.KEY_BUDGET_AMOUNT] = data.budget;
  options[OB.KEY_BID_TYPE] = 'AUTOMATIC';
  options[OB.KEY_BROWSER] = data.browser || '';
  options[OB.KEY_OS] = data.os || '';
  options[OB.KEY_AUDIENCE] = data.audience ? data.audience : null;
  // options[OB.KEY_CONVERSION] =
  //   data.conversion && data.conversion.length > 0 && data.conversion.length < 2
  //     ? data.conversion
  //     : data.conversion.splice(0, 1);

  if (options.prefix !== 'xx') {
    options[OB.KEY_STATUS] = data.enabled ? OB.ACTIVE : OB.INACTIVE;
  }
  return options;
}

export { OutbrainCreate };
